import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const PrivateRoute = ({ element }) => {
    const { isAuthenticated, loading } = useAuth();

    if (loading) {
        return <div>Chargement...</div>; 
    }

    return isAuthenticated ? element : <Navigate to="/connexion" />;
};

export default PrivateRoute;
